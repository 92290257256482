<script>
export default {
  metaInfo: {
    title: "Outbound Connections",
  }
};
</script>

<script setup>
import ConnectionsView from '@/components/basic/ConnectionsView.vue';
</script>

<template>
  <ConnectionsView url="?f=configuration&f2=outboundConnections&p=5G&c_subType=HSS"/>
</template>
